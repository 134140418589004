.navbar {
  display: flex;
  width: 100%;
  background-color: rgb(55, 105, 219);
  border: 4px solid rgb(0, 14, 94);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-radius: 50px;
}

.navbar-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.navbar-button {
  background-color: transparent;
  border: none;
  font-family: monospace;
  color: white;
  font-weight: bold;
  padding-right: 5px;
  padding-left: 5px;
}

.s-telegram-image {
  width: 30%;
}

.s-twitter-image {
  width: 100%;
}

.navbar-button {
  flex: 1;
}

.s-twitter-image {
  width: 50%;
  height: auto; /* Maintain aspect ratio */
}

.s-telegram-image {
  width: 35%;
  height: auto; /* Maintain aspect ratio */
}
