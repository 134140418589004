.s-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("../../images/socialsBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  padding-bottom: 700px;
  padding-top: 50px;
}

.s-title {
  width: 50%;
}

.s-text {
  font-size: 30px;
  padding: 40px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  color: rgb(0, 69, 129);
  text-align: center;
}

.s-button-image {
  width: 30%;
}

.s-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.s-twitter-button {
  width: 100%;
}
.s-button {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
