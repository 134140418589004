.container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.title {
  width: 100%;
  padding: 20px;
}

.hero {
  width: 100%;
}

.hero2 {
  width: 90%;
}

@media only screen and (max-width: 2000px) {
  .title {
    width: 60%;
  }
  .hero {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .title {
    width: 80%;
  }
  .hero {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .title {
    width: 100%;
  }
}
