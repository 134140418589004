.ca-container {
  height: 300px;
  background-color: rgb(95, 125, 182);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.address {
  width: 50%;
  background-color: white;
  border: 5px solid black;
  border-radius: 20px;
  height: 30px;
  text-align: center;
  font-family: Calibri, "Trebuchet MS", sans-serif;
}

.ca-title {
  width: 50%;
}

.input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.copy-button {
  background-color: rgb(36, 69, 141);
  padding: 10px;
  border-radius: 50px;
  font-size: 18px;
  margin-left: 10px;
  border: 2px solid black;
  font-family: Calibri, "Trebuchet MS", sans-serif;
}
